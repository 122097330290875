import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import loading_img from "../../../images/profile/loading.gif";
import { useHistory, useLocation } from "react-router-dom";

const BetTechComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [renderer, setRenderer] = useState(null); // Store BTRenderer instance
  const sel_Language = localStorage.getItem('selectedLanguage');
  const auth_token = localStorage.getItem('auth_token');
  const selectedCurrency = localStorage.getItem('selectedCurrency');
  useEffect(() => {
    const data = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
      currency: selectedCurrency,
      language: sel_Language,
    };
    const loadBetTech = async () => {
      try {
        if (auth_token) {
          const response = await axios.post('/sportsbook', data);
          loadBetTechScript(response.data.data.token);
        } else {
          loadBetTechScript(null);
        }
      } catch (error) {
        console.error("Error loading BetTech script:", error);
      }
    };

    loadBetTech();

    return () => {
      // Cleanup script when the component unmounts
      const script = document.getElementById("btrenderer-script");
      if (script) {
        script.remove();
      }
    };
  }, [history]);

    const loadBetTechScript = (token) => {
      // Check if BTRenderer is already loaded in the window object
      if (window.BTRenderer) {
        setIsScriptLoaded(true);
        initializeBetTechRenderer(token);
        return;
      }

      // Load script if BTRenderer is not present
      if (!document.getElementById("btrenderer-script")) {
        const script = document.createElement("script");
        script.src = "https://slotediscover.sptpub.com/bt-renderer.min.js";
        script.async = true;
        script.defer = true;
        script.id = "btrenderer-script";
        script.onload = () => {
          setIsScriptLoaded(true);
          initializeBetTechRenderer(token);
        };
        document.head.appendChild(script);
      }
    };

    const initializeBetTechRenderer = (token) => {
      if (window.BTRenderer) {
        const btRendererInstance = new window.BTRenderer().initialize({
          brand_id: "2448549541360836616",
          token: token || "",
          themeName: "demo-red-dark-card",
          lang: sel_Language,
          target: document.getElementById("bettech"),
          minFrameHeight: 700,
          betSlipOffsetTop: 77,
          stickyTop: 77,
          betslipZIndex: 999,
          onRouteChange: function () {
            console.log("on route change", arguments);
          },
          onLogin: function () {
            history.push('?login=true');
          },
          onRegister: function () {
            swal("Warning", "Not enough funds in the account", "warning");
            history.push('/register');
          },
          onSessionRefresh: function () {
            // Handle session refresh logic
          },
          onRecharge: function () {
            swal("Warning", "Not enough funds in the account", "warning");
            history.push('/deposit');
          },
          onTokenExpired: onTokenExpired,
        });
        setRenderer(btRendererInstance);
      }
    };





  const onTokenExpired = () => {
    return new Promise((resolve, reject) => {
      fetch(
        "https://provider.slotediscover.com/api/refresh-token?currency=USD&language=en",
        {
          method: "GET",
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((launchData) => {
          console.log("Token refresh successful:", launchData.data.token);
          resolve(launchData.data.token); // Resolving the promise with the new token
        })
        .catch((error) => {
          console.error("There was an error refreshing the token:", error);
          reject(error); // Reject the promise if an error occurs
        });
    });
  };
  

    // Handle URL query changes
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const btPathd = query.get('bt-path');
    if (btPathd && renderer) {
      //console.log("Updating options with bt-path:", btPathd);
      // Update the renderer options dynamically without reinitializing the application
      renderer.updateOptions({ url: btPathd });
    }
  }, [location.search, renderer]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
    const query = new URLSearchParams(location.search);
    const btPathd = query.get('bt-path');
      if (!btPathd && location.pathname !==  "/sportsbook") {
        console.log("kill kill kill kill:", btPathd);
        renderer.kill();
      } else if (renderer && renderer.kill) {
        console.log("no kill no kill no kill no kill:", btPathd);
      }
    });

    return () => {
      unlisten(); // Stop listening to route changes when the component unmounts
    };
  }, [history, renderer]);

  return (
    <div>
      <style>
        {`
          @media (max-width: 767px) { /* Mobile view */
            .iframe-container {
              margin:-20px -12px 0px -12px;
              height: 100%;
            }
          }

          @media (min-width: 768px) and (max-width: 1200px) { /* Tablet and up */
            .iframe-container {
              margin:-25px -34px 0px -34px;
              height: 100%;
            }
          }

          @media (min-width: 1200px) { /* Tablet and up */
            .iframe-container {
              margin:-50px -34px 0px -34px;
              height: 100%;
            }
          }
        `}
      </style>
      <div className="iframe-container">
        {isScriptLoaded ? (
          <div
            id="bettech"
            style={{ width: "100%", height: "100%", backgroundColor: "#000000", color: "white", zIndex: 1000 }}
          ></div>
        ) : (
         <div className="d-flex justify-content-center align-items-center vh-100">
            <img src={loading_img} alt="" className="me-3 rounded" width={75} />
         </div>
        )}
      </div>
    </div>
  );
};

export default BetTechComponent;
