import React, { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import { ButtonGroup, Button, Col } from 'react-bootstrap';

const Home = () => {
  const [onHomeGame, setOnHomeGame] = useState(null);
  const [loading, setLoading] = useState(true);
  const videoRef = useRef(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchGameData = async () => {
      try {
        const { data } = await axios.post('/get-one-game', {
          key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
          id: id,
        });
        setOnHomeGame(data.game_link);
      } catch (error) {
        console.error("Error fetching game data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGameData();
  }, [id]);

  const handleToggleFullScreen = () => {
    const video = videoRef.current;
    if (video) {
      if (video.requestFullscreen) video.requestFullscreen();
      else if (video.mozRequestFullScreen) video.mozRequestFullScreen();
      else if (video.webkitRequestFullscreen) video.webkitRequestFullscreen();
      else if (video.msRequestFullscreen) video.msRequestFullscreen();
    }
  };

  return (
    <div className="d-xl-block" style={{ marginTop: '-36px' }}>
      <div className="row">
        <div className="card p-0 mb-0" style={{ width: '100vw', height: '100vh', position: 'relative' }}>
          {loading ? (
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
              <span className="fa fa-spinner fa-spin text-black" style={{ fontSize: "50px" }}></span>
            </div>
          ) : (
            <iframe
              ref={videoRef}
              className="rounded"
              title="Game"
              src={onHomeGame}
              frameBorder="0"
              style={{ width: '100%', height: '100%', zIndex: 2 }}
              allowFullScreen
            />
          )}
        </div>
        <Col xl="12">
          <ButtonGroup style={{ float: "right" }}>
            <Button variant="primary"><span className="fa fa-heart"></span></Button>
            <Button variant="primary"><span className="fa fa-heart"></span></Button>
            <Button onClick={handleToggleFullScreen} variant="primary"><span className="fa fa-expand"></span></Button>
          </ButtonGroup>
        </Col>
      </div>
    </div>
  );
};

export default Home;
